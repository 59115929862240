import React, { useState } from 'react';
import Modal from "react-modal";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

Modal.setAppElement("#wrapper");

const Contact = () => {
  const [title, setTitle] = useState(null);
  const [message, setMessage] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [uuid, setUuid] = useState(null);
  const [error, setError] = useState(null);
  
  const toggleModal = e => {
    setIsOpen(!isOpen);
  };

  const sleep = ms => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  return (

    <Formik
      initialValues={{ name: '', company: '', email: '', phone: '', message: '' }}
      validationSchema={Yup.object({
        name: Yup.string()
          .max(100, 'Must be 100 characters or less')
          .required('Required'),
        phone: Yup.string()
          .max(20, 'Must be 20 characters or less')
          .required('Required'),
        email: Yup.string()
          .max(50, 'Must be 50 characters or less')
          .email('Invalid email address')
          .required('Required'),
        message: Yup.string()
          .required('Required'),
      })}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        await sleep(500);

        const modal = document.querySelector("#modal");
        const body = JSON.stringify(values, null, 2)

        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: body
        };
        fetch('api/contact', requestOptions)
          .then(response => {
            if (response.status >= 400) {
              setTitle("Server is currently down!");
              setMessage("Please try again later when we're back up and running. Thank you!");
            } else {
              setTitle("Thank you for reaching out!");
              setMessage("We'll get back to you ASAP with inquiries on your quote.");
              resetForm();
            }
            setSubmitting(false);
            toggleModal();
          })
          .then(data => {
            setUuid(data)
          })
          .catch(error => {
            setError(error);
            setSubmitting(false);
            toggleModal();
          });
      }}
    >
      
      <div className="form-widget">

        <div className="form-result"></div>

        <Form className="row mb-0">

          <div className="form-process">
            <div className="css3-spinner">
              <div className="css3-spinner-scale-ripple">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>

          <div className="col-md-6 mb-4">
            <Field name="name" type="text" className="required sm-form-control border-form-control" placeholder="Your Name" />
            <ErrorMessage name="name" component="div" className="error-form" />
          </div>
          <div className="col-md-6 mb-4">
            <Field name="company" type="text" className="sm-form-control border-form-control" placeholder="Company Name" />
          </div>

          <div className="w-100"></div>

          <div className="col-md-6 mb-4">
            <Field name="email" type="email" className="required email sm-form-control border-form-control" placeholder="Email" />
            <ErrorMessage name="email" component="div" className="error-form" />
          </div>

          <div className="col-md-6 mb-4">
            <Field name="phone" type="phone" className="required sm-form-control border-form-control" placeholder="Phone Number" />
            <ErrorMessage name="phone" component="div" className="error-form" />
          </div>

          <div className="w-100"></div>

          <div className="col-12 mb-4">
            <Field name="message" type="text" className="required sm-form-control border-form-control" component="textarea" placeholder="Tell us about your project" />
            <ErrorMessage name="message" component="div" className="error-form" />
          </div>

          <div className="col-12 center mb-4">
            <button className="button button-border button-circle font-weight-medium ml-0 topmargin-sm" type="submit">Let's Scope My Project</button>
            <br/>
            <small className="form-footer">We'll get back to you ASAP.</small>
          </div>
          
        </Form>

        <Modal
          isOpen={isOpen}
          onRequestClose={toggleModal}
          contentLabel="Thank you!"
          style={{
            overlay: {
              zIndex: 10
            },
            content: {
              position: 'absolute',
              border: '1px solid #ccc',
              background: '#fff',
              borderRadius: '10px',
              outline: 'none',
              padding: '20px',
              minWidth: "300px",
              maxHeight: "250px",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)"
            }
          }}
        >
          <h3 className="font-body font-weight-normal ls1">{title}</h3>
          <div>{message}</div>
          <div className="center">
            <button className="button button-border button-circle font-weight-medium ml-0 topmargin-sm center" onClick={toggleModal}>Close</button>
          </div>
            
        </Modal>

      </div>

    </Formik>
  );
}

export default Contact;